import React from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import Header from "../components/page/Header"
import { ThemeProvider } from "@material-ui/styles"
import { theme } from "../var/theme"
import NoSsr from "@material-ui/core/NoSsr"
import { Card, CardContent, createStyles, makeStyles, useScrollTrigger } from "@material-ui/core"
import Zoom from "@material-ui/core/Zoom"
import Fab from "@material-ui/core/Fab"
import { KeyboardArrowUpRounded as KeyboardArrowUpIcon } from "@material-ui/icons"
import { scrollTo } from "../var/util"
import Footer from "../components/partials/Footer"
import Meta from "../components/Meta"
import Typography from "@material-ui/core/Typography"
import DownloadSimple from "../components/partials/DownloadSimple"
import { Link } from "gatsby"

const useStyles = makeStyles((t) => createStyles({
  main: {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  },
  scroll: {
    position: "fixed",
    bottom: t.spacing(2),
    right: t.spacing(2),
  },
  toolbar: {
    ...t.mixins.toolbar,
    background: theme.custom.fancyBackground,
  },
  content: {
    flex: 1,
  },
  card: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
  },
  featureList: {
    listStyleType: "none",
    marginLeft: 0,
    paddingLeft: "1em",
    textIndent: "-1em",
  },
  link: {
    textDecoration: "underline",
    fontSize: 16,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

function ScrollTop(props) {
  const { children, window } = props
  const classes = useStyles()
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = event => {
    scrollTo("#top-anchor")
  }

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.scroll}>
        {children}
      </div>
    </Zoom>
  )
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

const IndexPage = () => {
  const classes = useStyles()
  // const prefersDarkMode = 0//useMediaQuery("(prefers-color-scheme: dark)")
  // const appTheme = React.useMemo(
  //   () =>
  //     prefersDarkMode ? darkTheme : theme,
  //   [prefersDarkMode],
  // )
  return (
    <React.Fragment>
      <Meta title={"About"} />
      <NoSsr>
        <ThemeProvider theme={theme}>
          <CssBaseline/>
          <main className={classes.main}>
            <Header title="Memo Reminders"/>
            <section id={"content"} className={classes.content}>
              <div className={classes.toolbar}/>
              <Container maxWidth={"md"}>
                {/*<PageTitle title={"Help"}/>*/}
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"center"} variant={"h6"}>What is Memo Reminders?</Typography>
                    <Typography align={"left"} variant={"body1"}>
                      Memo Reminders is application that will help you to do the thing you are often "forgetting",
                      postponing, have a "valid" excuse for not doing it or simply: <b>procrastinating</b>.
                    </Typography>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"center"} variant={"h6"}>Why Memo Reminders?</Typography>
                    <Typography align={"left"} variant={"body1"}>
                      "Memo Reminders" is simple, fast and easy to get started. It is not standard To-Do or Reminders
                      application because there is no strict time setting. Just write what you need to do and tap the
                      button and done.
                    </Typography>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"center"} variant={"h6"}>How does it work?</Typography>
                    <Typography align={"left"} variant={"body1"}>
                      <b>On your mobile device</b> "Memo reminders" creates notifications which can be seen in
                      notification
                      drawer. Easily accessible, always there.
                    </Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      Notifications are also shown on the lock-screen, you will see you
                      quick memos or reminders every time when you check your phone.
                    </Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      Don't want some of them on the lock screen? Don't worry, there is option for that.
                    </Typography>
                    <Typography align={"left"} variant={"body1"}>
                      Perfect for making quick grocery list before going to shop.
                    </Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      Just swipe it away when done.
                    </Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      <b>On your browser</b> "Memo reminders" replaces your new tab page and there you can create
                      reminders and you will see them each time you open new tab, additionally there is button with
                      reminders count in your toolbar to remind you in case you don't open new tab that often.
                    </Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      Again, easily accessible, always there.
                    </Typography>
                    <Typography align={"left"} variant={"body1"}>
                      You want some of them to be hidden on the new tab page? Don't worry, there is option for
                      that.
                    </Typography>
                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"center"} variant={"h6"}>Advanced Features</Typography>
                    <Typography align={"left"} variant={"body1"} component={"div"} gutterBottom>
                      <ul className={classes.featureList}>
                        <li>★ Highly customizable: set icon, short text, add longer description if needed</li>
                        <li>★ Keeps history of your memo reminders</li>
                        <li>★ Add actions on reminders - Customize the action when you tap on the notification. You can
                          set the
                          action to dial number, prepare SMS text or email someone, navigate to your destination, open
                          website
                          if you need to book tickets, launch application(ex. launch Camera app for #SelfieOfTheDay)…
                          you
                          name
                          it!
                          Saves you time and effort.
                        </li>
                      </ul>
                    </Typography>
                    <Typography align={"left"} variant={"body1"} gutterBottom>
                      Not enough space for description? Use Emojis! <span role={"img"}
                                                                          aria-label={"Thumbs up"}>👍</span>
                    </Typography>
                    <Typography align={"left"} variant={"body1"}>
                      Check our <Link to={"/how-to"} className={classes.link}>how-to</Link> page to learn more about
                      Memo
                      Reminders.
                    </Typography>

                  </CardContent>
                </Card>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography align={"center"} variant={"h6"}>Get it now</Typography>
                    <DownloadSimple/>
                  </CardContent>
                </Card>
              </Container>
            </section>
            <Footer excludeSupport={true}/>
          </main>
          <ScrollTop>
            <Fab color="secondary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon/>
            </Fab>
          </ScrollTop>
        </ThemeProvider>
      </NoSsr>
    </React.Fragment>
  )
}

export default IndexPage
